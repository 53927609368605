<template>
    <div class="patentList contBox">
        <div class="search-top flex-between searchBox">
          <el-form
            :inline="true"
            :model="formData"
            ref="searchForm"
            class="demo-form-inline"
          >

          <el-form-item label="时间：">
              <el-date-picker
                v-model="searchData.time"
                type="daterange"
                value-format="YYYY-MM-DD"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item label="姓名：">
              <el-input
                v-model="searchData.name"
                class="w-180"
                clearable @input="handleSearch"
                placeholder="请输入姓名"
              >
                <template #prefix>
                  <i class="fa fa-search"></i>
                </template>
              </el-input>
            </el-form-item>
            
            <el-form-item label="年龄">
              <el-select  v-model="searchData.age" clearable>
                <el-option label = '全部' value="1">
                </el-option>
                <el-option label = '≥60周岁' value="2">
                </el-option>
                <el-option label = '＜60周岁' value="3">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="班次">
              <el-select v-model="searchData.banci" placeholder="请选择" clearable>
                <el-option label="上午" value="上午"></el-option>
                <el-option label="下午" value="下午"></el-option>
                <el-option label="晚上" value="晚上"></el-option>
              </el-select>
            </el-form-item>

        <el-form-item label="透析模式">
                <el-select
                
                    v-model="searchData.txmoshi"
                    multiple
                    placeholder="请选择透析模式"
                    style="width: 240px"
                    >
                    <el-option
                        v-for="item in dictList['100000000']"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                    />
                    </el-select>
            </el-form-item>


        <el-form-item label="血管通路">
                <el-select
                
                    v-model="searchData.dict_tonglu"
                    placeholder="请选择通路方式"
                    style="width: 240px"
                    >
                    <el-option
                        v-for="item in dictList['110000000']"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                    />
                    </el-select>
            </el-form-item>

            <el-form-item label="透析器">
                <el-select
                
                    v-model="searchData.txq"
                    multiple
                    placeholder="请选择"
                    style="width: 240px"
                    >
                    <el-option
                        v-for="item in dictList['113000000']"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                    />
                    </el-select>
            </el-form-item>

            <el-form-item label="血滤器">
                <el-select
                
                    v-model="searchData.xlq"
                    multiple
                    placeholder="请选择"
                    style="width: 240px"
                    >
                    <el-option
                        v-for="item in dictList['114000000']"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                    />
                    </el-select>
            </el-form-item>

            <el-form-item label="收缩压">
                  <div style="width: 200px; display: flex;">
                      <el-input 
                          type="number" 
                          v-model="searchData.ssy.start" 
                          @input="(val) => searchData.ssy.start = val.replace(/[^-\d]/g, '')">
                      </el-input>
                      <p style="margin: 0 5px;"> - </p>
                      <el-input 
                          type="number" 
                          v-model="searchData.ssy.end" 
                          @input="(val) => searchData.ssy.end = val.replace(/[^-\d]/g, '')">
                      </el-input>
                  </div>
              </el-form-item>

              <el-form-item label="舒张压">
                  <div style="width: 200px; display: flex;">
                      <el-input 
                          type="number" 
                          v-model="searchData.szy.start" 
                          @input="(val) => searchData.szy.start = val.replace(/[^-\d]/g, '')">
                      </el-input>
                      <p style="margin: 0 5px;"> - </p>
                      <el-input 
                          type="number" 
                          v-model="searchData.szy.end" 
                          @input="(val) => searchData.szy.end = val.replace(/[^-\d]/g, '')">
                      </el-input>
                  </div>
              </el-form-item>

        <el-form-item label="透析器阻塞：">
          <el-select clearable v-model="searchData.dict_touxiqi_zuse" placeholder="请选择">
            <el-option label="无" value="">
            </el-option>
            <el-option v-for="item in dictList[640000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="体外循环血路管阻塞：" label-width="160px">
          <el-select clearable v-model="searchData.dict_xuelvqi_zuse" placeholder="请选择">
            <el-option label="无" value="">
            </el-option>
            <el-option v-for="item in dictList[650000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>

          </el-form>
          <div class="searchBut">
            <!-- <el-button class="color-yellow" @click="resetForm" type="primary">
              <i class="fa fa-print"></i>批量打印
            </el-button> -->
            <!-- <el-button @click="handleSearch" class="color-main" type="primary">
              <i class="fa fa-file-text-o"></i>修改模板
            </el-button> -->
            <el-button class="color-blue" @click="handleSearch" type="primary">
              <i class="fa fa-search"></i>查询
            </el-button>
          </div>
        </div>


  <!-- tabs标签页 -->
  <div>
      <el-table v-loading="loading" ref="multipleTableRef" :data="tableData" style="width: 100%" height="550" border>
        <el-table-column property="patient.name" label="姓名" />
        <el-table-column property="patient.age" label="年龄" />
        <el-table-column property="created_time" label="透析日期" />
        <el-table-column property="dict_code" label="班次" />
        <el-table-column property="dict_dialysis_type_name" label="透析模式" />
        <el-table-column property="dict_tonglu_name" label="血管通路" />
        <el-table-column property="ssy" label="收缩压" />
        <el-table-column property="szy" label="舒张压" />
        <el-table-column  label="体重增长率(%)" >
<template #default="scope">{{ scope.row.befor_raise_weight_rate || '--' }}</template>
            </el-table-column>
        <el-table-column property="dict_touxiqi_name" label="透析器" />
        <el-table-column property="dict_xuelvqi_name" label="血滤器" />
        <el-table-column property="touxiqi_zuse" label="透析器阻塞" />
        <el-table-column property="xuelvqi_zuse" label="血滤器阻塞" />
      </el-table>
      <div class="pagination-box">
          <el-pagination :currentPage="pageData.currentPage" :page-size="pageData.pageSize"
            :page-sizes="[10, 20, 50, 100]" :background="true" layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.totalSum" @size-change="handleSizeChange"
            @current-change="handleCurrentChange"></el-pagination>
        </div>
    </div>

  </div>


</template>
<script setup>
import { reactive, ref, onMounted, defineExpose, onBeforeUnmount,onBeforeMount } from 'vue'

import { gitDictList } from '@/utils/tool'

import service from '@/utils/request'
import dayjs from 'dayjs'
import searchBox from '@/components/search/searchBox.vue'
import comm from '@/utils/comm'

const tableData = ref([])
const tableLoading = ref(false)
const pageData = reactive({
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        })
const searchData = reactive({
  time: [],
  name: '',
  age: '',
  banci: '',
  txmoshi: [],
  ssy: {
    start:'',
    end:'',
  },
  szy: {
    start:'',
    end:'',
  },
  txq: [],
  xlq: [],
  dict_touxiqi_zuse: '',
  dict_xuelvqi_zuse: '',
  dict_tonglu: '',
})
onBeforeMount(async()=>{
   await getDict()
   getDataList()
})

 // 获取字典数据
 const dictList = ref({})
 const  getDict=async()=> {
    dictList.value = await gitDictList(['100000000','113000000','114000000','640000000','650000000','110000000'])
}

const handleSearch = () => {
  pageData.currentPage = 1

  getDataList()
}

const handleSizeChange = (val) => {
        pageData.pageSize = val
        getDataList()
      }

const handleCurrentChange = (val) => {
        pageData.currentPage = val
        getDataList()
      }

const getDataList = async () => {
  searchData.current = pageData.currentPage
  searchData.size = pageData.pageSize
  if (searchData.time.length > 0) {
    searchData.start_time = searchData.time[0]
    searchData.end_time = searchData.time[1]
  }
  tableLoading.value = true
  let res = await service.post('/api/dialysis_record/dialysisCount', searchData)
  tableLoading.value = false
  if (res.code === 0) {
    tableData.value = res.data.list
    pageData.totalSum = res.data.count
    if (tableData.value.length > 0) {
      singleTableRef.value.setCurrentRow(tableData.value[0])
    }
  }
}

</script>

<style scoped lang="scss">
.table {
  margin-top: 0;
}
.patentList {
  height: 100%;
}
.searchBox {
  .el-form {
    margin-top: 0;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .w-180 {
    width: 180px;
  }
  &:deep(.el-date-editor) {
    width: 300px;
  }
  .el-select {
    width: 144px;
  }
}
.search-top .demo-form-inline {
  margin-top: 0;
  .el-form-item {
    margin-bottom: 0;
  }
}
.dialysisReportList {
  margin-top: 8px;
  height: calc(100% - 55px);
  .list-left {
    width: 400px;
    margin-right: 8px;
  }
  .dialysis-pdf {
    width: calc(100% - 408px);
    padding-top: 0;
    padding-left: 0;
  }
}
.dialysis-pdf {
  .explain {
    height: 40px;
    line-height: 40px;
    color: #3166ae;
    background: rgba(49, 102, 174, 0.1);
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 0 16px;
    .name {
      font-size: 18px;
      margin-right: 10px;
    }
  }
}
.pdfBox {
  height: calc(100% - 66px);
}
</style>
